import Subscribe from './common/Subscribe';
import PROTOCOL_CONFIG from './config/protocolConfig';
import Util from './Util';

// 屏幕刷新频率一般为60fps
const MOVE_THROTTLE_DELAY = 16.6;
const ORIENTATION_PORTRAIT = 'PORTRAIT';
const KEYBOARD_ID_PREFIX = 'phoenix_keyboard_';
const ORIENTATION_DEGRESS = {
    'PORTRAIT': 0,
    'LANDSCAPE': 90,
    'REVERSE_LANDSCAPE': -90,
    'REVERSE_PORTRAIT': 180
};
const KEYBOARD_MODE = {
    'KEYBOARD_MAP': 'KEYBOARD_MAP',
    'KEYBOARD_INPUT': 'KEYBOARD_INPUT'
};

export default class TouchHandler {
    constructor({player, isMobile, sendHandler, keyboard, isDebug, autoRotate, inputId = ''}) {
        // 监听触控鼠键事件的Dom
        this.displayDom = player;
        // 事件回调，可通过该函数获取模拟事件
        this.sendHandler = sendHandler;
        // 是否移动端
        this.isMobile = isMobile;
        // debug下，显示调测过程信息
        this.isDebug = isDebug;
        this.autoRotate = autoRotate;
        // 远端云手机信息
        this.phone = {...PROTOCOL_CONFIG.DEFAULT_RESOLUTION};
        this.resolution = {...PROTOCOL_CONFIG.DEFAULT_RESOLUTION};
        // 游戏画面方向，默认竖屏，若启动竖屏游戏，CAE不发送旋转msg
        this.gameOrientation = ORIENTATION_PORTRAIT;
        // player显示内容区坐标相对contain的偏移
        this.displayBox = {
            top: 0,
            left: 0,
            ...PROTOCOL_CONFIG.DEFAULT_RESOLUTION
        };
        // 本地播放画面相对远端手机大小的比例
        this.scale = {
            x: 1,
            y: 1
        };
        this.keyboardMode = KEYBOARD_MODE.KEYBOARD_MAP;
        // 存储方向键信息
        this.direction = {
            key: 'WASD',
            radius: 0.05
        };
        // 存储键盘映射信息
        this.keyboardMap = this.formatKeyboard(keyboard);
        this.keyboardDisplaying = false;
        // 存储模拟数据
        this.simulationState = {
            mouse: {},
            keyboard: {},
            touch: {}
        };
        // 多点触控不超过20个触点
        this.virtualTouchIds = new Uint8Array(20);
        this.mouseCode = {
            primary: 0,
            assist: 1,
            secondary: 2
        };
        // 是否拖拽模式
        this.dragMode = true;
        this.util = new Util();
        this.touchListKey = 'changedTouches';
        // 存储用于定时触发move事件的定时器
        this.moveTimer = {};
        this.subscribe = new Subscribe(['keysPositionChange']);
        this.viewportOrientation = window.orientation;
        this.isApple = /(iPhone|iPad|iPod|iOS|Macintosh)/i.test(navigator && navigator.userAgent || '');
        this.inputId = inputId;
    }
    
    start() {
        this.updateScale();
        // 需要显示键盘按钮时才执行以下操作
        if (!this.isMobile) {
            this.calcDisplayKeyboardMap();
            this.triggerKeysPositon();
            this.keyboardDisplaying && this.showKeyboard();
        }

        this.resize();
        this.util.bind(window, 'resize', this.resize.bind(this));
        if (this.displayDom) {
            if (this.isMobile) {
                this.util.bind(this.displayDom, 'touchstart', this.onTouchStart.bind(this));
                this.util.bind(this.displayDom, 'touchend', this.onTouchEnd.bind(this));
                this.util.bind(this.displayDom, 'touchmove', this.onTouchMove.bind(this));
                this.util.bind(this.displayDom, 'touchcancel', this.onTouchEnd.bind(this));
            } else {
                this.util.bind(this.displayDom, 'mousedown', this.onMouseDown.bind(this));
                this.util.bind(this.displayDom, 'mouseup', this.onMouseUp.bind(this));
                this.util.bind(this.displayDom, 'mousemove', this.util.throttle(
                    this.onMouseMove.bind(this), MOVE_THROTTLE_DELAY, this).bind(this));
                
                this.util.bind(this.displayDom, 'contextmenu', () => false);
    
                this.util.bind(window, 'keydown', this.onKeyDown.bind(this));
                this.util.bind(window, 'keyup', this.onKeyUp.bind(this));
            }
    
            this.displayDom.focus();

            this.autoRotate && this.util.bind(window, 'orientationchange', this.viewportOrientationChange.bind(this));
        }
    }

    viewportOrientationChange() {
        this.viewportOrientation = window.orientation;
    }

    /**
     * 监听事件，如监听keysPositionChange事件
     * @param {string} eventName 事件名称
     * @param {object} callback 回调函数
     **/
    on(eventName, callback) {
        this.subscribe.on(eventName, callback);
    }

    /**
     * 解除事件监听
     * @param {string} eventName 事件名称
     * @param {object} callback 回调函数
     **/
    off(eventName, callback) {
        this.subscribe.off(eventName, callback);
    }

    /**
     * 更新游戏方向
     * @param {string} orientation 游戏方向
     **/
    updateGameOrientation(orientation) {
        this.gameOrientation = orientation;
        this.resize();
    }

    updateResolution(width, height) {
        this.resolution = {width, height};
        this.resize();
    }

    updateKeyboardMode(mode) {
        if (!KEYBOARD_MODE[mode]) { 
            return;
        }

        this.keyboardMode = mode;
    }

    /**
     * 键盘信息格式化
     * @param {object} keyboard 键盘配置信息
     * @returns {object}
     */
    formatKeyboard(keyboard) {
        const keys = keyboard && Object.keys(keyboard) || [];
        let result = {};
        keys.forEach(key => {
            const upperKey = key.toUpperCase();
            if (!this.direction.key.includes(upperKey) || this.direction.key === upperKey) {
                result[upperKey] = keyboard[key];
            }
        });
    
        const radius = (result.WASD && result.WASD.radius) || this.direction.radius;
        Object.assign(this.direction, {
            W: {y: -radius},
            A: {x: -radius},
            S: {y: radius},
            D: {x: radius}
        });
        return result;
    }
    
    // 是否支持键盘
    isKeyboardOperate() {
        return this.keyboardMap && Object.keys(this.keyboardMap).length;
    }

    isKeyboardMapMode() {
        return this.keyboardMode === 'KEYBOARD_MAP';
    }
    
    // 指定位置显示红点，用于调测时在触发事件的位置显示
    showPos(pos) {
        if (this.isDebug) {
            const size = 20;
            const radius = 10;
            const id = 'posTracker';
            const cssText = `
                position: absolute;
                border-radius: ${radius}px;
                width: ${size}px;
                height: ${size}px;
                background: red;
                left: ${pos.x + this.displayBox.left - radius}px;
                top: ${pos.y + this.displayBox.top - radius}px;
                `;
            let dom = this.displayDom.parentNode.querySelector(`#${id}`);
            if (!dom) {
                dom = document.createElement('div');
                dom.id = id;
                this.displayDom.after(dom);
            }
    
            dom.style.cssText = cssText;
        }
    }
    
    /**
     * 对应位置显示按键
     * @param {object} pos 位置
     * @returns {string} key 按键
     */
    showKey(pos, key) {
        const id = `${KEYBOARD_ID_PREFIX}${key}`;
        const borderWidth = 3;
        const size = this.displayBox.height;
        let keySizeRate = 0.04;
        let keySize = keySizeRate * size;
        let dom = this.displayDom.parentNode.querySelector(`#${id}`) || document.createElement('div');
        let text = key;
        const lineHeight = keySize;
        if (key === this.direction.key) {
            keySizeRate = 0.08;
            keySize = keySizeRate * size;
            text = `
                <span id="${KEYBOARD_ID_PREFIX}W" class="${KEYBOARD_ID_PREFIX}W" 
                    style="position:absolute; top: -5px; left: 0;right: 0">W</span>
                <span id="${KEYBOARD_ID_PREFIX}A" class="${KEYBOARD_ID_PREFIX}A" 
                    style="position:absolute; top: 0; bottom: 0; left: 0; line-height: ${lineHeight * 2}px;">A</span>
                <span id="${KEYBOARD_ID_PREFIX}D" class="${KEYBOARD_ID_PREFIX}D" 
                    style="position:absolute; top: 0; bottom: 0; right: 0; line-height: ${lineHeight * 2}px;">D</span>
                <span id="${KEYBOARD_ID_PREFIX}S" class="${KEYBOARD_ID_PREFIX}S" 
                    style="position:absolute; bottom: -5px; left: 0;right: 0">S</span>
                `;
        }

        const cssText = `
            left: ${pos.x - (keySize / 2) - borderWidth}px;
            top: ${pos.y - (keySize / 2) - borderWidth}px;
            position: absolute;
            pointer-events: none;
            width: ${keySize}px;
            height: ${keySize}px;
            border-radius: ${keySize}px;
            border: ${borderWidth}px solid black;
            background-color: #00000099;
            color: #FFFFFF;
            text-align: center;
            font-size: 14px;
            line-height: ${lineHeight}px;
            display: block;
            `;
        dom.style.cssText = cssText;
        // 显示客户配置的按键，由客户做XSS防范，用户接触不到，因此该处不做转义
        dom.innerHTML = text;
        if (!dom.id) {
            this.displayDom && this.displayDom.parentNode.appendChild(dom);
            dom.id = id;
            dom.class = id;
        }
    }
    
    hideKey(key) {
        let dom = this.displayDom.parentNode.querySelector(`#${KEYBOARD_ID_PREFIX}${key}`);
        dom && (dom.style.display = 'none');
    }

    // 显示键盘，仅支持PC接入时显示，移动端接入不显示
    showKeyboard() {
        // 该函数对外提供，此处不能去掉是否是移动端的判断
        if (this.isMobile) {
            return;
        }

        const keys = this.displayKeyboardMap && Object.keys(this.displayKeyboardMap) || [];
        keys.forEach(key => {
            // keyboardMap是相对播放内容区的位置，showKey显示的是相对外部container的位置
            const pos = this.displayKeyboardMap[key];
            this.showKey({x: pos.x + this.displayBox.left, y: pos.y + this.displayBox.top}, key);
        });
        this.keyboardDisplaying = true;
    }

    // 隐藏键盘
    hideKeyboard() {
        const keys = this.displayKeyboardMap && Object.keys(this.displayKeyboardMap) || [];
        keys.forEach(key => {
            this.hideKey(key);
        });
        this.keyboardDisplaying = false;
    }

    setKeyboard(keyboard) {
        if (this.isMobile || typeof keyboard !== 'object') {
            return;
        }

        const oldKeyboardMap = this.keyboardMap;
        this.keyboardMap = this.formatKeyboard(keyboard);
        // 删掉已经被移除按键
        const newKeys = Object.keys(this.keyboardMap);
        Object.keys(oldKeyboardMap).forEach(key => {
            if (!newKeys.includes(key)) {
                const dom = this.displayDom.parentNode.querySelector(`#${KEYBOARD_ID_PREFIX}${key}`);
                dom && dom.remove();
            }
        });
        this.calcDisplayKeyboardMap();
        this.triggerKeysPositon();
        this.keyboardDisplaying && this.showKeyboard();
    }

    triggerKeysPositon() {
        const keysPosition = {};
        const keys = this.displayKeyboardMap && Object.keys(this.displayKeyboardMap) || [];
        keys.forEach(key => {
            // keyboardMap是相对播放内容区的位置，显示是相对外部container的位置
            const pos = this.displayKeyboardMap[key];
            keysPosition[key] = {left: pos.x + this.displayBox.left, top: pos.y + this.displayBox.top};
        });
        this.subscribe.trigger('keysPositionChange', keysPosition);
    }
    
    /**
     * 计算多个方向键合成后的偏移量
     * @param {object} keys 方向键数组
     * @returns {object} 偏移量
     */
    calcDelta(keys) {
        let delta = {x: 0, y: 0};
        keys && keys.forEach(key => {
            if (this.direction.key.includes(key)) {
                delta.x += (this.direction[key].x * this.displayBox.width || 0);
                delta.y += (this.direction[key].y * this.displayBox.width || 0);
            }
        });

        return delta;
    }
    
    /**
     * 获取Android触控事件类型
     * @param {string} type H5触控事件类型
     * @param {object} touchRecords 已触发的触控鼠键记录
     * @returns {string} Android触控事件类型
     */
    getTouchAction(type, touchRecords) {
        const mouseStateNum = touchRecords.mouse && Object.keys(touchRecords.mouse).length || 0;
        const touchNum = touchRecords.touch && Object.keys(touchRecords.touch).length || 0;
        // 方向键合成一个vId，需要去重
        const keyboardNum = [...new Set(touchRecords.keyboard && Object.values(touchRecords.keyboard) || [])].length;
        const total = mouseStateNum + touchNum + keyboardNum;
        let prefix = '';
        if ((type === 'UP' && total) || (type === 'DOWN' && total > 1)) {
            prefix = 'POINTER_';
        }
    
        return prefix + type;
    }
    
    /**
     * 获取按键的虚拟ID
     * @param {string} key 按键
     * @returns {string} 虚拟ID
     */
    getVirtualTouchId(key) {
        if (this.direction.key.includes(key)) {
            let keys = Object.keys(this.direction);
            key = keys.find((k => this.simulationState.keyboard[k] !== undefined));
        }
    
        return this.simulationState.keyboard[key];
    }
        
    /**
     * 获取当间已经按下的方向键的目标位置
     * @param {object} 方向键的中心原始位置
     * @returns {object} 目标位置
     */
    getTargetPos(pos) {
        const vId = this.getVirtualTouchId(this.direction.key);
        if (vId === undefined) {
            return pos;
        }
    
        const keys = Object.keys(this.simulationState.keyboard);
        const delta = this.calcDelta(keys);
        pos = {
            x: pos.x + delta.x,
            y: pos.y + delta.y
        };   
        return pos;
    }

    /**
     * 模拟当前已经按下的方向键的move事件
     * @param {object} 方向键的中心原始位置
     */
    refreshDirect(pos) {
        const vId = this.getVirtualTouchId(this.direction.key);
        if (vId === undefined) {
            return;
        }

        pos = this.getTargetPos(pos);
        this.sendTouchMsg({
            ...pos,
            vId
        }, this.getTouchAction('MOVE', this.simulationState));
        this.showPos(pos);
    }

    /**
     * 兼容各浏览器，获取按键, 忽略大小写
     * @param {Event} key事件Event
     * @returns {key, keyCode, isWASD}
     */
    _parseKey(event) {
        event = event || window.event;
        let key = event.key || '';
        const identifier = event.keyIdentifier;
        const shortName = {
            Control: 'Ctrl',
            Escape: 'Esc',
            Backspace: 'Back',
            CapsLock: 'Caps'
    
        };
        if (identifier) { // safari
            if (identifier.startsWith('U+')) {
                key = String.fromCharCode(parseInt(identifier.substr(2), 16));
            } else if (shortName[identifier]) {
                key = shortName[identifier];
            } else {
                key = identifier;
            }
        }
    
        key = key.toUpperCase();
        let isWASD = this.direction.key.includes(key);
        let pos = this.displayKeyboardMap[key] || (isWASD && this.displayKeyboardMap[this.direction.key]);
        return {key, isWASD, pos, keyCode: event.keyCode};
    }
    
    /**
     * 在起始点和终点间以一定步长模拟一次move事件
     * @param {string} vId 虚拟ID
     * @param {object} cur 起始位置
     * @param {object} end 终点位置
     * @returns {object} next move一定步长后的位置
     */
    moveNext(vId, cur, end) {
        let next = end;
        if (cur.x !== end.x || cur.y !== end.y) {
            const STEP_LEN = 8;
            const sign = {
                x: end.x < cur.x ? -1 : 1,
                y: end.y < cur.y ? -1 : 1
            };
            next = {
                x: cur.x + Math.min(STEP_LEN, Math.abs(end.x - cur.x)) * sign.x,
                y: cur.y + Math.min(STEP_LEN, Math.abs(end.y - cur.y)) * sign.y
            };
        }

        this.sendTouchMsg({
            ...next,
            vId
        }, this.getTouchAction('MOVE', this.simulationState));
        this.showPos(next);
        return next;
    }

    /**
     * 定时在起始点和终点间以一定步长模拟一系列move事件
     * @param {string} vId 虚拟ID
     * @param {object} cur 起始位置
     * @param {object} end 终点位置
     */
    startMoveSimulation(vId, start, end) {
        let next = this.moveNext(vId, start, end);
        this.moveTimer[vId] && clearInterval(this.moveTimer[vId]);
        this.moveTimer[vId] = setInterval(() => {
            next = this.moveNext(vId, next, end);
        }, MOVE_THROTTLE_DELAY);
    }

    /**
     * 停止定时模拟move事件
     * @param {string}} vId 虚拟ID
     */
    stopMoveSimulation(vId) {
        if (!this.moveTimer[vId]) {
            return;
        }
        
        clearInterval(this.moveTimer[vId]);
        this.moveTimer[vId] = null;
    }

    onKeyDown(event) {
        event = event || window.event;
        let {isWASD, key, pos} = this._parseKey(event);
        if (!this.isKeyboardOperate() || !this.isKeyboardMapMode() || !pos || this.simulationState.keyboard[key] !== undefined) {
            return;
        }
    
        let vId = this.getVirtualTouchId(key);
        if (vId === undefined) {
            vId = this.allocVirtualTouchId();
            this.simulationState.keyboard[key] = vId;
            this.sendTouchMsg({
                ...pos,
                vId
            }, this.getTouchAction('DOWN', this.simulationState));

            let target = isWASD ? this.getTargetPos(pos) : pos;
            this.startMoveSimulation(vId, pos, target);
        } else {
            this.simulationState.keyboard[key] = vId;
            this.stopMoveSimulation(vId);
            this.refreshDirect(pos);
        }
    }
    
    onKeyUp(event) {
        event = event || window.event;
        let {isWASD, key, pos} = this._parseKey(event);
        if (!this.isKeyboardOperate() || !this.isKeyboardMapMode() || !pos || this.simulationState.keyboard[key] === undefined) {
            return;
        }
    
        const lastPos = isWASD ? this.getTargetPos(pos) : pos;
        const oldVId = this.getVirtualTouchId(key);
        delete this.simulationState.keyboard[key];
        const newVId = this.getVirtualTouchId(key);
        if (newVId === undefined) {
            this.freeVirtualTouchId(oldVId);
            this.sendTouchMsg({
                ...lastPos,
                vId: oldVId
            }, this.getTouchAction('UP', this.simulationState));
            this.stopMoveSimulation(oldVId);
            this.showPos(lastPos);
        } else {
            this.stopMoveSimulation(newVId);
            this.refreshDirect(pos);
        }
    }
    
    // 阻止原生事件
    preventDefault(event) {
        event.stopPropagation();
        event.preventDefault();
    }

    onTouchStart(event) {
        event = event || window.event;
        this.preventDefault(event);

        // 解决ios接入，收到云端真机输入事件时真机input框focus不了的问题。解决方案：触控事件中focus
        if (this.inputId && this.isApple && this.keyboardMode === KEYBOARD_MODE.KEYBOARD_INPUT) {
            let input = document.getElementById(this.inputId);
            if (input) {
                input.focus();
            }
        }

        // TouchList是array like结构的对象，包含length属性。
        const touchList = event[this.touchListKey] || {length: 0};
        for (let len = touchList.length, index = 0; index < len; index++) {
            const touch = touchList.item(index) || {};
            const existTouch = this.simulationState.touch[touch.identifier];
            if (existTouch) {
                this.sendTouchMsg(existTouch, 'UP');
                this.freeVirtualTouchId(existTouch.vId);
                delete this.simulationState.touch[touch.identifier];
            }
    
            touch.vId = this.allocVirtualTouchId();
            this.simulationState.touch[touch.identifier] = touch;
            const pos = this.getCurrentMousePos(touch);
            this.sendTouchMsg({
                ...pos,
                vId: touch.vId
            }, this.getTouchAction('DOWN', this.simulationState));
        }
    }
    
    onTouchMove(event) {
        event = event || window.event;
        this.preventDefault(event);
        // TouchList是array like结构的对象，包含length属性。
        const touchList = event[this.touchListKey] || {length: 0};
        for (let len = touchList.length, index = 0; index < len; index++) {
            const touch = touchList.item(index) || {};
            const existTouch = this.simulationState.touch[touch.identifier];
            const pos = this.getCurrentMousePos(touch);
            this.sendTouchMsg({
                ...pos,
                vId: existTouch && existTouch.vId || 0
            }, 'MOVE');
        }
    }

    onTouchEnd(event) {
        event = event || window.event;
        this.preventDefault(event);
        // TouchList是array like结构的对象，包含length属性。
        const touchList = event[this.touchListKey] || {length: 0};
        for (let len = touchList.length, index = 0; index < len; index++) {
            const touch = touchList.item(index) || {};
            const existTouch = this.simulationState.touch[touch.identifier];
            if (existTouch) {
                this.freeVirtualTouchId(existTouch.vId);
                delete this.simulationState.touch[touch.identifier];
            }
    
            const pos = this.getCurrentMousePos(touch);
            this.sendTouchMsg({
                ...pos,
                vId: existTouch && existTouch.vId || 0
            }, this.getTouchAction('UP', this.simulationState));
        }
    }
    
    _mouseAction(event, action) {
        const mouseCode = event.button;
        const pos = this.getCurrentMousePos(event);
        let mouseState = this.simulationState.mouse;
        let vId = mouseState[mouseCode];
        if (action === 'UP') {
            this.freeVirtualTouchId(vId);
            delete mouseState[mouseCode];
        } else {
            vId = this.allocVirtualTouchId();
            mouseState[mouseCode] = vId;
        }
    
        this.sendTouchMsg({
            ...pos,
            vId
        }, this.getTouchAction(action, this.simulationState));
    }
    
    onMouseDown(event) {
        event = event || window.event;
        this.preventDefault(event);
        if (event.button === this.mouseCode.assist) {
            this.dragMode = !this.dragMode;
            if (!this.dragMode) {
                this._mouseAction(event, 'DOWN');
            } else {
                this._mouseAction(event, 'UP');
            }
        } else {
            this._mouseAction(event, 'DOWN');
        }
    }
    
    onMouseMove(event) {
        event = event || window.event;
        this.preventDefault(event);
        const mouseCodes = Object.keys(this.simulationState.mouse);
        const mouseCode = mouseCodes.find(code => code in this.simulationState.mouse);
        if (mouseCode !== undefined) {
            let pos = this.getCurrentMousePos(event);
            this.sendTouchMsg({
                ...pos,
                vId: this.simulationState.mouse[mouseCode]
            }, 'MOVE');
        }
    }

    onMouseUp(event) {
        event = event || window.event;
        this.preventDefault(event);
        if (event.button !== this.mouseCode.assist) {
            this._mouseAction(event, 'UP');
        }
    }
    
    // 计算键盘按键位置映射信息
    calcDisplayKeyboardMap() {
        let displayKeyboardMap = {};
        if (this.isKeyboardOperate()) {
            let keys = Object.keys(this.keyboardMap);
            keys.forEach(key => {
                let pos = this.keyboardMap[key];
                displayKeyboardMap[key] = {
                    x: pos.left * this.displayBox.width,
                    y: pos.top * this.displayBox.height
                };
            });
        }
    
        this.displayKeyboardMap = displayKeyboardMap;
    }
    
    isSameOrientation() {
        // 若游戏横屏，当前认为游戏方向为逆时针旋转90度。
        // 故若不自动旋转，游戏竖屏时，浏览器和游戏方向一致；
        // 若自动旋转，1）真机方向竖屏，则游戏会旋转至和浏览器一个方向，2）若真机左旋转或又旋转至横屏，则游戏会旋转至浏览器逆时针90度的位置
        return ((!this.autoRotate && this.gameOrientation === ORIENTATION_PORTRAIT)
            || (this.autoRotate && (this.viewportOrientation === ORIENTATION_DEGRESS.PORTRAIT || this.viewportOrientation === ORIENTATION_DEGRESS.REVERSE_PORTRAIT)));
    }

    getScale(current, target) {
        if (this.isSameOrientation()) {
            return {
                x: current.width ? (target.width / current.width) : 1,
                y: current.height ? (target.height / current.height) : 1
            };
        }

        return {
            x: current.height ? (target.width / current.height) : 1,
            y: current.width ? (target.height / current.width) : 1
        };
    }
    
    // 更新缩放比例
    updateScale() {
        this.scale = this.getScale(this.displayBox, this.phone);
    }
    
    // 更新player显示区域信息
    updateContainBox(container) {
        const scaleObj = this.getScale(container, this.resolution);
        const scale = Math.max(scaleObj.x, scaleObj.y);
        if (this.isSameOrientation()) {
            this.displayBox.width = this.resolution.width / scale;
            this.displayBox.height = this.resolution.height / scale;
        } else {
            this.displayBox.width = this.resolution.height / scale;
            this.displayBox.height = this.resolution.width / scale;
        }
    
        this.displayBox.left = (container.width - this.displayBox.width) >> 1;
        this.displayBox.top = (container.height - this.displayBox.height) >> 1;
    }
    
    // 界面resize，更新显示区域信息和键盘位置信息，以及缩放比例等和大小位置相关的信息
    resize(width, height) {
        if (!this.displayDom) {
            return;
        }
    
        if (width && height) {
            this.displayBox.width = width;
            this.displayBox.height = height;
        } else {
            // 旋转后，通过getClientRects拿到的宽高为旋转后宽高，所见即所得，如（宽，高）=（200px, 100px）的块旋转90度后获取到（宽，高）=（100px, 200px）
            const displayRects = this.displayDom.getClientRects();
            this.displayBox.width = displayRects && displayRects[0] && displayRects[0].width;
            this.displayBox.height = displayRects && displayRects[0] && displayRects[0].height;
        }

        this.displayBox.top = 0;
        this.displayBox.left = 0;
        // 若player等比例填充，则实际内容区的宽高并非获取到的宽高
        if (this.displayDom.style.objectFit === 'contain') {
            this.updateContainBox({...this.displayBox});
        }
    
        this.updateScale();
        // 需要显示键盘按钮时才更新键盘位置
        if (!this.isMobile) {
            this.calcDisplayKeyboardMap();
            this.triggerKeysPositon();
            this.keyboardDisplaying && this.showKeyboard();
        }
    }
    
    /**
     * 计算触控点相对于游戏画面左上角的位置信息
     * @param {object} eventTouch 触控事件
     * @return {object} 位置
     */
    getCurrentMousePos(eventTouch) {
        const docRects = document.documentElement.getClientRects()[0];
        const displayRects = this.displayDom.getClientRects()[0];
        return {
            x: eventTouch.pageX - displayRects.left - this.displayBox.left,
            y: eventTouch.pageY - displayRects.top - this.displayBox.top + docRects.top
        };
    }
    
    // 游戏横屏表示逆时针旋转了90度
    // 发送给server的(x, y)相对于手机竖屏时左上角的点。
    // 不管手机横屏还是竖屏，或是css旋转，监听到的触控点(x, y)的值所见即所得，其参考点始终是浏览器左上角；
    // 远端游戏在本地横屏还是竖屏是相对浏览器的，也就是说，最终显示（不管是否有旋转）存在角度的都需要做位置转换
    transform(pos) {
        if (this.isSameOrientation()) {
            return {
                x: Math.ceil(pos.x * this.scale.x),
                y: Math.ceil(pos.y * this.scale.y)
            };
        }
    
        return {
            x: Math.ceil((this.displayBox.height - pos.y) * this.scale.x),
            y: Math.ceil(pos.x * this.scale.y)
        };
    }
    
    // 分配虚拟ID
    allocVirtualTouchId() {
        const index = this.virtualTouchIds.indexOf(0);
        if (index === -1) {
            this.virtualTouchIds.map(() => 0);
            this.virtualTouchIds[0] = 1;
            return 0;
        }
    
        this.virtualTouchIds[index] = 1;
        return index;
    }
    
    // 释放虚拟ID
    freeVirtualTouchId(virtualId) {
        this.virtualTouchIds[virtualId] = 0;
    }
    
    getMsgBuf(data) {
        if (!this.sendHandler) {
            return;
        }
    
        const PACKAGE_HEADER_LENGTH = 8;
        const TOUCH_MSG_BODY_LENGTH = 8;
        const TOUCH_ACTION = 6;

        let buf = new Uint8Array(PACKAGE_HEADER_LENGTH + TOUCH_MSG_BODY_LENGTH);
        buf[0] = 90;
        buf[1] = 90;
        buf[2] = 180 + TOUCH_ACTION;
        buf[3] = TOUCH_ACTION;
        buf[7] = TOUCH_MSG_BODY_LENGTH;
        buf[8] = data.id;
        buf[9] = data.action;
        buf[10] = (data.x >> 8) & 0xFF;
        buf[11] = data.x & 0xFF;
        buf[12] = (data.y >> 8) & 0xFF;
        buf[13] = data.y & 0xFF;
        return buf;
    }

    /**
     * 通过回调，发送模拟的触控事件
     * touchstart : 触摸开始（手指放在触摸屏上）
     * touchmove : 拖动（手指在触摸屏上移动）
     * touchend : 触摸结束（手指从触摸屏上移开）
     * touchenter ：移动的手指进入一个dom元素。
     * touchleave ：移动的手指离开一个dom元素。
     */
    sendTouchMsg(touch, action) {
        touch.x = Math.max(touch.x, 0);
        touch.y = Math.max(touch.y, 0);
        touch.x = Math.min(touch.x, this.displayBox.width);
        touch.y = Math.min(touch.y, this.displayBox.height);
        const pos = this.transform(touch);
        const msg = {
            ...pos,
            action: PROTOCOL_CONFIG.ACTIONS_TYPE[action],
            id: touch.vId
        };
        const msgBuf = this.getMsgBuf(msg);
        this.sendHandler(msgBuf);
    }
    
    destroy() {
        this.util.unbind(window, 'resize');
        this.util.unbind(window, 'keydown');
        this.util.unbind(window, 'keyup');
        this.util.unbind(this.displayDom);
        this.autoRotate && this.util.unbind(window, 'orientationchange');
        Object.keys(this.moveTimer).forEach(key => {
            this.moveTimer[key] && clearInterval(this.moveTimer[key]);
        });

        // 删除增加的keyboard Dom
        if (this.displayDom && this.displayDom.parentNode) {
            const childrens = this.displayDom.parentNode.children || [];
            const keys = this.displayKeyboardMap && Object.keys(this.displayKeyboardMap) || [];
            let ids = keys.map(key => `${KEYBOARD_ID_PREFIX}${key}`);
            ids.push('posTracker');
            let removeList = [];
            for (let i = 0, len = childrens.length; i < len; i++) {
                if (ids.indexOf(childrens[i].id) > -1) {
                    removeList.push(childrens[i]);
                }
            }

            removeList.forEach(item => this.displayDom.parentNode.removeChild(item));
        }

        this.displayDom = null;
    }
}

