import AppController from './AppController';
import PROTOCOL_CONFIG from './config/protocolConfig';
import FullScreen from './Fullscreen';
import Logger from './Logger';
import NoDebugger from './NoDebugger';

const WEBSOCKET_PREFIX = 'wss://';
const DEFAULT_RECONNECT_TIMES = 15;

/**
 * 生成一个游戏实例
 * 
 * @param {string} containerId - 用于显示云手机应用的div的id。
 * @param {String} options.ip - 接入游戏使用ip地址。例：127.0.0.1
 * @param {String} options.port - 接入游戏使用端口。例：12345
 * @param {String} options.package_name - 游戏启动包名。例：com.huawei.xxx
 * @param {String} options.launcher_activity - 游戏启动文件路径。例：com.huawei.xxx.xxxActivity
 * @param {String} options.app_id - 云游戏唯一id，在华为云创建应用生成的id
 * @param {String} options.session_id - 用户本次接入游戏的会话id。客户后台生成的32为uuid，中间无空格、无连接符。
 * @param {String} options.game_timeout - 用户玩游戏时home的时长，用户离开游戏界面，home切换到后台。
 * @param {String} options.available_playtime - 用户可以玩游戏的时长，用户本次接入最大可玩时长。
 * @param {String} options.ticket - 256位随机数，客户后台生成的随机数，用于认证。
 * @param {String} options.aes_key - 生成用于本次接入游戏过程中用到的对称密钥。
 * @param {String} options.auth_ts - 生成当前时间的时间戳,用于鉴权模块验签时校验时效性。
 * @param {String} options.token - 用户登录的账号信息，会透传给云端游戏。
 * @param {Object} [options.input_options.keyboard=null] - 键盘按键到游戏画面的映射关系, 相对游戏画面左上角的偏移百分比值，其中wasd限定为4向方向键。例: {wasd: {x: 0.1, y: 0.8}, j: {x: 0.8, y: 0.9}},说明支持wasd控制方向，中心在应用视图左下角（left: 10%; top: 80%）；支持j按键控制应用视图的右下角（left: 80%; top: 90%）
 * @param {string} options.user_id - 用户ID
 * @param {string} options.touch_timeout - 无操作超时, 超时后断开连接
 * @param {boolean} options.auto_rotate - 根据游戏和屏幕方向自动横竖屏
 * @param {Object} [options.media_config=null] - 多媒体参数
 * @param {boolean} options.remote_ime - 是否开启输入法端云协同
 * @param {boolean} options.change_remote_ime - 是否支持根据游戏和真机方向动态切换真机键盘或云机键盘（该开关在auto_rotate和remote_ime均为true的情况下有效）
 * @param {boolean} [options.need_heartbeat=true] - 是否需要维持心跳，远程登录phone的场景不需要心跳
 * @param {boolean} [options.reconnect_times=15] - 断链后重连的次数，默认值以保证向前兼容
 */
class CloudApp {
    constructor(containerId, options) {
        let defaults = {};
        // 参数校验
        let msg = this._verify(options);
        if (msg) {
            throw msg;
        } else {
            this.options = {...defaults, containerId, ...options};
            this._init_();
        }

        options._debug !== true && NoDebugger.protect();
    }

    static isSupport() {
        return AppController.isSupport();
    }

    static getVersion() {
        /*global __APP_VERSION__*/
        return __APP_VERSION__;
    }

    static setAppController() {
        const userOptions = this.options;
        let connectURI = userOptions.connect_uri || '';
        let splitPos = connectURI.startsWith(WEBSOCKET_PREFIX) ? WEBSOCKET_PREFIX.length : 0;
        connectURI = connectURI.substr(splitPos);
        let options = {
            phoneIp: userOptions.ip,
            phonePort: String(userOptions.port),
            connectURI: connectURI || [userOptions.ip, userOptions.port].join(':'),
            packageName: userOptions.package_name,
            packageLaunchActivity: userOptions.launcher_activity || '',
            appId: String(userOptions.app_id),
            sessionId: String(userOptions.session_id),
            gameTimeout: String(userOptions.game_timeout),
            availablePlayTime: String(userOptions.available_playtime),
            ticket: String(userOptions.ticket),
            aesKey: String(userOptions.aes_key),
            authTimeStamp: String(userOptions.auth_ts),
            token: String(userOptions.token),
            containerId: userOptions.containerId,
            userId: String(userOptions.user_id || ''),
            username: String(userOptions.username || ''),
            password: String(userOptions.password || ''),
            cloudName: String(userOptions.cloudName || ''),
            agent: String(userOptions.agent || ''),
            inputOptions: userOptions.input_options,
            /*global __IS_DEBUG__*/
            isDebug: __IS_DEBUG__,
            oldApi: userOptions._mock === true,
            isMobile: /(iPhone|iPad|iPod|iOS|Android)/i.test(navigator.userAgent),
            supportAudio: true,
            sdkVersion: this.version,
            touchTimeout: String(Math.max(Number(userOptions.touch_timeout || 0), 0)),
            remoteIme: userOptions.remote_ime,
            changeRemoteIme: userOptions.change_remote_ime === undefined ? true : userOptions.change_remote_ime,
            needHeatBeat: userOptions.need_heartbeat === undefined ? true : userOptions.need_heartbeat,
            reconnectTimes: userOptions.reconnect_times === undefined ? DEFAULT_RECONNECT_TIMES : userOptions.reconnect_times
        };
        options.autoRotate = userOptions.auto_rotate && options.isMobile;
        if (userOptions.media_config) {
            options.mediaConfig = userOptions.media_config;
        }

        return new AppController(options);
    }

    _init_() {
        /*global __APP_VERSION__*/
        this.version = __APP_VERSION__;
        this.appController = CloudApp.setAppController.bind(this)();
        this.appController.start();
        this.fullscreen = new FullScreen({
            containerId: this.options.containerId,
            autoRotate: this.appController.options.autoRotate
        });
        this.fullscreen.init();
        window.addEventListener('unload', () => {
            this.exit();
        });
    }
    
    __defaultValidator (name, val, rule) {
        if (rule.startsWith('len')) {
            let subs = rule.split(':');
            if (subs.length === 2 && val.length !== Number(subs[1])) {
                return `${name}'length is ${subs[1]}.`;
            }

            if (subs.length === 3 && (val.length < Number(subs[1]) || val.length > Number(subs[2]))) {
                return `${name}'length is ${subs.splice(1)}.`;
            }
        }

        if (rule.startsWith('fn')) {
            let subs = rule.split(':');
            let fn = subs[1];
            if (!fn || !this[fn]) {
                return '';
            }

            return this[fn](val);
        }

        return '';
    };

    _verify(options) {
        let schema = {
            ip: ['require'],
            port: ['require'],
            package_name: ['require'],
            app_id: ['require'],
            session_id: ['require'],
            ticket: ['require'],
            aes_key: ['require', 'hex', 'len:32'],
            auth_ts: ['require'],
            token: ['require'],
            touch_timeout: ['number'],
            media_config: ['fn:_verifyMediaConfig']
        };
        let msg = '';

        if (!options) {
            return '初始化CloudApp需要一个object类型参数';
        }

        let keys = Object.keys(options);
        for (let i = 0, kLen = keys.length; i < kLen; i++) {
            let name = keys[i];
            let rules = schema[name] || [];
            let val = options[name];
            let subs;
            let error;

            for (let j = 0, rLen = (rules || []).length; j < rLen; j++) {
                switch (rules[j]) {
                    case 'require':
                        !val && (msg = `${name} is required.`);
                        break;
                    case 'number':
                        isNaN(Number(val)) && (msg = `${name} is a number.`);
                        break;
                    case 'hex':
                        !/^[a-f0-9]*$/i.test(val) && (msg = `${name} is a hex string.`);
                        break;
                    case 'port':
                        (Number(val) < 0 || Number(val) > 65535) && (msg = `${name} is a port.`);
                        break;
                    case 'ip':
                        subs = val.split('.');
                        error = subs && subs.find(sub => (Number(sub) < 0 || Number(sub) > 255));
                        (subs.length !== 4 || error) && (msg = `${name} is a ip.`);
                        break;
                    default:
                        msg = this.__defaultValidator(name, val, rules[j]);
                        break;
                }

                if (msg) {
                    return msg;
                }
            }
        }

        return msg;
    }

    _verifyMediaConfig(config) {
        if (typeof config !== 'object') {
            return 'Media config should be an object.';
        }

        if ((config.virtual_width && !config.virtual_height) || (!config.virtual_width && config.virtual_height)) {
            return 'virtual_width and virtual_height should be configured at the same time if you need.';
        }

        let schema = {
            frame_rate: [{
                type: 'number',
                min: 10,
                max: 60
            },
            {
                type: 'multiple',
                value: 10
            }],
            bitrate: [{
                type: 'number',
                min: 1000000,
                max: 10000000
            }],
            virtual_width: [{
                type: 'number',
                min: 240,
                max: 4096
            },
            {
                type: 'multiple',
                value: 8
            }],
            virtual_height: [{
                type: 'number',
                min: 240,
                max: 4096
            },
            {
                type: 'multiple',
                value: 8
            }]
        };

        const keys = Object.keys(config);
        for (let i = 0, kLen = keys.length; i < kLen; i++) {
            const name = keys[i];
            const val = config[name];
            const rules = schema[name] || [];
            for (let j = 0, rLen = rules.length; j < rLen; j++) {
                let rule = rules[j];
                switch (rule.type) {
                    case 'number': {
                        let numberVal = Number(val);
                        if (isNaN(numberVal)) {
                            return `${name} is a number.`;
                        }

                        if (rule.min !== undefined && numberVal < rule.min) {
                            return `${name} should be larger than ${rule.min}.`;
                        }

                        if (rule.max !== undefined && numberVal > rule.max) {
                            return `${name} should be smaller than ${rule.max}.`;
                        }

                        break;
                    }

                    case 'multiple':
                        if (rule.value && Number(val) % rule.value !== 0) {
                            return `${name} should be a multiple of ${rule.value}.`;
                        }

                        break;
                    default:
                }
            }
        }

        return '';
    }

    /*
     * @return {number} 当前音量值，数值范围在 0 ~ 100
     */
    getVolume() {
        return this.appController.getVolume();
    }

    /*
     * @param {number} value 要设置的音量值，数值范围为 0 ~ 100
     */
    setVolume(value) {
        if (typeof value !== 'number') {
            return;
        }

        if (value < 0) {
            value = 0;
        } else if (value > 100) {
            value = 100;
        }

        this.appController.setVolume(value);
    }

    exit() {
        this.appController.exit();
        this.fullscreen.destroy();
        this.appController = null;
        this.fullscreen = null;
    }

    pause() {
        this.appController.pauseGame();
    }

    resume() {
        this.appController.resumeGame();
    }

    reconnect() {
        this.appController.reconnect();
    }

    // fullscreenElementId: 全屏元素ID。不能在同一个dom上旋转和全屏，所以若自动旋转则需另提供全屏的dom id，如containerId的父级dom
    fullscreenToggle(fullscreenElementId) {
        this.fullscreen.fullscreenToggle(fullscreenElementId);
    }

    isFullscreen() {
        return this.fullscreen.isFullscreen();
    }

    on(eventName, callback) {
        if (eventName === 'logReceived') {
            Logger.onLogReceived(callback);
        } else {
            this.appController.on(eventName, callback);
        }
    }

    off(eventName, callback) {
        this.appController.off(eventName, callback);
    }

    showKeyboard() {
        this.appController.showKeyboard();
    }

    hideKeyboard() {
        this.appController.hideKeyboard();
    }

    setKeyboard(keyboard) {
        this.appController.setKeyboard(keyboard);
    }

    setResolution(resolution) {
        if (typeof resolution !== 'object') {
            Logger.debug('resolution should be an object');
            return;
        }

        this.appController.setResolution(resolution.width, resolution.height);
    }

    setMediaConfig(config) {
        const msg = this._verifyMediaConfig(config);
        if (msg) {
            return msg;
        }

        this.appController.setMediaConfig(config);
    }

    /**
     * 发送数据到云游戏
     * @param {object} data 待发送到云游戏的数据，数据为ArrayBuffer类型化数组
     * @return {undefined} 
     */
    sendDataToCloudGame(data) {
        this.appController.sendDataToCloudGame(data);
    }
}

CloudApp.RESOLUTIONS = {...PROTOCOL_CONFIG.RESOLUTIONS};

export default CloudApp;
