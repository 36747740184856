import Util from './Util';

const ORIENTATION_DEGRESS = {
    'PORTRAIT': 0,
    'LANDSCAPE': 90,
    'REVERSE_LANDSCAPE': -90,
    'REVERSE_PORTRAIT': 180
};
const FULLSCREEN_CHANGE = ['webkitfullscreenchange', 'mozfullscreenchange', 'fullscreenchange', 'msFullscreenChange'];

export default class AutoRotation {
    constructor(videoContainerId, gameOrientation, callbackAfterRotate) {
        this.viewportOrientation = window.orientation || 0;
        this.gameOrientation = gameOrientation;
        this.videoContainerId = videoContainerId;
        this.videoContainer = document.getElementById(videoContainerId);
        this.originStyle = this.videoContainer && this.videoContainer.cssText || '';
        this.callbackAfterRotate = callbackAfterRotate;
        this.util = new Util();
        this.resizeTimer = null;
    }

    resize() {
        this.viewportOrientation = window.orientation || 0;
        this.rotatePlayer();
    }

    init() {
        // 若监听orientationChange事件，真机旋转后，需延时才能获取到真机旋转后屏幕宽高；监听resize事件，可立即获取旋转后的宽高。
        this.util.bind(window, 'resize', this.resize.bind(this));
        this.util.bind(window, 'orientationchange', this.resize.bind(this));
        FULLSCREEN_CHANGE.forEach(evt => {
            this.util.bind(document, evt, () => {
                setTimeout(() => {
                    this.rotatePlayer();
                }, 300);
            });
        });
    }

    updateGameOrientation(orientation) {
        this.gameOrientation = orientation;
        this.rotatePlayer();
    }

    rotatePlayer() {
        window.scrollTo(0, 0);
        let width = document.documentElement.clientWidth;
        let height = document.documentElement.clientHeight;
        const container = this.videoContainer || document.getElementById(this.videoContainerId);
        if (!container) {
            return;
        }

        const rect = container.parentNode && container.parentNode.getClientRects()[0];
        if (rect) {
            width = Math.max(width - Math.max(rect.left, 0), 0);
            height = Math.max(height - Math.max(rect.top, 0), 0);
        }
        
        let style = '';
        let rotateDegrees = 0;
        // 根据本地视口方向和游戏方向判断播放器dom的旋转方向，将播放器（同游戏方向）旋转至视口方向。顺时针为正数，逆时针为负数
        if ((Math.abs(this.viewportOrientation - ORIENTATION_DEGRESS[this.gameOrientation]) % 180) === 0) {
            style = `
                -webkit-transform: none;
                -moz-transform: none;
                -ms-transform: none;
                transform: none;
                width: ${width}px;
                height: ${height}px;
                position: relative;
                top: 0px;
                left: 0px;
            `;
        } else {
            // 云游戏横屏时顺时针旋转，竖屏时逆时针旋转
            rotateDegrees = Math.abs(ORIENTATION_DEGRESS[this.gameOrientation]) === 90 ? 90 : -90;
            style = `
                -webkit-transform: rotate(${rotateDegrees}deg);
                -moz-transform: rotate(${rotateDegrees}deg);
                -ms-transform: rotate(${rotateDegrees}deg);
                transform: rotate(${rotateDegrees}deg);
                -webkit-transform-origin: '50% 50%';
                -moz-transform-origin: '50% 50%';
                -ms-transform-origin: '50% 50%';
                transform-origin: '50% 50%';
                width: ${height}px;
                height: ${width}px;
                position: relative;
                top: ${(height - width) / 2}px;
                left: ${0 - (height - width) / 2}px;
            `;
        }

        container.style.cssText = this.originStyle + style;

        // 旋转后通过getClientRects()立即获取宽高可能会获取到旋转前的宽高
        this.callbackAfterRotate && setTimeout(() => {
            this.callbackAfterRotate(rotateDegrees);
        }, 300);
    }

    destroy() {
        const container = this.videoContainer || document.getElementById(this.videoContainerId);
        container && (container.style.cssText = this.originStyle);
        this.util.unbind(window, 'resize');
        this.util.unbind(window, 'orientationchange');
        FULLSCREEN_CHANGE.forEach(evt => {
            this.util.unbind(document, evt);
        });
    }
}
