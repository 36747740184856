export default class Subscribe {
    constructor(eventNames) {
        this._events = {};
        (eventNames || []).forEach(name => {
            this._events[name] = [];
        });
    }

    trigger(eventName, data) {
        let events = this._events[eventName];
        events && events.forEach(fn => {
            fn && fn.call(this, {name: eventName}, data);
        });
    }
    
    on(eventName, callback) {
        if (eventName in this._events) {
            this._events[eventName].push(callback);
        }
    }
    
    off(eventName, callback) {
        let events = this._events[eventName];
        if (events && callback) {
            for (let idx = events.length - 1; idx >= 0; idx--) {
                if (events[idx] === callback) {
                    events.splice(idx, 1);
                }
            }
        }
    }
}
