const CANVAS_WHITE_LIST = {
    mate20: 'HMA-',
    mate20Pro: 'LYA-',
    mate20RS: 'LYA-AL00P',
    mate20X: 'EVR-',
    mate20X5G: 'EVR-AN00',
    mate30: 'TAS-',
    mate305G: 'TAS-AN00',
    mate30Pro: 'LIO-',
    mate30Pro5G: 'LIO-AN00',
    mateX: 'TAH-AN00',
    mate40_an00: 'OCE-AN00',
    mate40_an01: 'OCE-AN01',
    mate40_an10: 'OCE-AN10',
    mate40Pro_an00: 'NOH-AN00',
    mate40Pro_an01: 'NOH-AN01',
    mate40ProPlus: 'NOP-AN00',
    p30: 'ELE-',
    p30Pro: 'VOG-',
    P40_an: 'ANA-AN00',
    P40_al: 'ANA-AL00',
    P40_tn: 'ANA-TN00',
    p40Pro_an: 'ELS-AN00',
    p40Pro_tn: 'ELS-TN00',
    p40ProPlus_an: 'ELS-AN10',
    p40ProPlus_tn: 'ELS-TN10',
    nova5: 'SEA-AL00',
    nova5Pro: 'SEA-AL10',
    nova5iPro: 'SPN-',
    nova5z: 'SPN-AL00',
    nova6: 'WLZ-AL10',
    nova65G: 'WLZ-AN00',
    nova6SE: 'JNY-AL10',
    nova75G_an0: 'JEF-AN00',
    nova75G_tn0: 'JEF-TN00',
    nova75G_an2: 'JEF-AN20',
    nova75G_tn2: 'JEF-TN20',
    nova7Pro5G_an0: 'JER-AN10',
    nova7Pro5G_tn0: 'JER-TN10',
    nova7Pro5G_an2: 'JER-AN20',
    nova7Pro5G_tn2: 'JER-TN20',
    honorMagic2: 'TNY-',
    honor20: 'YAL-AL00',
    honor20Pro: 'YAL-AL10',
    honor30: 'BMH-AN10',
    honor30Pro: 'EBG-AN00',
    honor30ProPlus: 'EBG-AN10',
    honor20S: 'YAL-AL50',
    honor30S: 'CDY-AN90',
    honorPlay4TPro: 'AQM-AL10',
    honor9X: 'HLK-AL00',
    honor9XPro: 'HLK-AL10',
    honorV20: 'PCT-',
    honorV30: 'OXF-AN00',
    honorV30Pro: 'OXF-AN10',
    honorX10: 'TEL-', // 各子型号后缀不同，故判断前缀
    m6_10_8: 'SCM-',
    m6_8_4: 'VRD-',
    matePadPro: 'MRX-',
    vivo: 'VivoBrowser',
    qqApp: 'QQ/',
    wechat: 'MicroMessenger'
};

export default CANVAS_WHITE_LIST;
