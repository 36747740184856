class NoDebuger {
    static protect() {
        if (!this.isInvade()) {
            this.listen();
        } else {
            this.kickit();
        }
    }

    static kickit() {
        while (this.isInvade()) {
            window.location.reload();
        }
    }

    static listen() {
        window.addEventListener('blur', () => {
            setTimeout(() => {
                this.kickit();
            }, 500);
        });
    }

    static isInvade() {
        let last = Date.now();
        // eslint-disable-next-line no-debugger
        debugger;
        return Date.now() - last > 10;
    }
}

export default NoDebuger;
