export default class Util {
    constructor() {
        this.bindEvents = [];
    }

    /**
     * 监听事件
     * @param {object} target 监听目标对象
     * @param {string} name 事件名称
     * @return {object} fn 事件处理函数
     */
    bind(target = window, name, fn) {
        if (!target.addEventListener || !name || !fn) {
            return false;
        }

        this.bindEvents.push({target, name, fn});
        target.addEventListener(name, fn, false);
        return true;
    }

    /**
     * 解除事件监听
     * @param {object} target 监听目标对象，可选
     * @param {string} name 事件名称，可选
     */
    unbind(target, name) {
        for (let i = this.bindEvents.length - 1; i >= 0; i--) {
            let item = this.bindEvents[i];
            if (item.target.removeEventListener
                && ((target && target === item.target) || !target)
                && ((name && name === item.name) || !name)) {
                item.target.removeEventListener(item.name, item.fn, false);
                this.bindEvents.splice(i, 1);
            }
        }
    }

    getUrlSearchVal(key) {
        let search = window.location.search.substr(1);
        let kv = search.split('&').map(kvs => {
            let [k, v] = kvs.split('=');
            return {k, v};
        }).find(item => item.k === key);
        return kv && decodeURIComponent(kv.v);
    }

    /**
     * 节流
     * @param {function} fn 要执行的方法
     * @param {number} delay 间隔时间
     */
    throttle(fn, delay, context) {
        let prev = Date.now();
        return function () {
            let args = arguments;
            if (Date.now() - prev >= delay) {
                fn.apply(context, args);
                prev = Date.now();
            }
        };
    }

    encodeUTF8(textStr) {
        const encodedStr = encodeURIComponent(textStr);
        const encodedArray = [];
        for (let i = 0; i < encodedStr.length; i++) {
            let cur = encodedStr.charAt(i);
            if (cur === '%') {
                let code = encodedStr.charAt(i + 1) + encodedStr.charAt(i + 2);
                encodedArray.push(parseInt(code, 16));
                i += 2;
            } else {
                encodedArray.push(cur.charCodeAt(0));
            }
        }

        return encodedArray;
    }

    decodeUTF8(utf8Array, start, end) {
        const len = utf8Array.length;
        start = start || 0;
        end = end || len;
        if (start < 0 || start >= len || end < 0 || end > len) {
            return '';
        }

        let encodedStr = '';
        for (let i = start; i < end; i++) {
            encodedStr += '%' + utf8Array[i].toString(16);
        }

        return decodeURIComponent(encodedStr);
    }
}

Util.saveAsFile = (() => {
    let size = 50 * 1024 * 1024;
    let fileBuf = null;
    let pos = 0;
    let downloadOver = false;
    let needHeader = false;
    const headerLength = 8;
    return frame => {
        fileBuf = fileBuf || new Uint8Array(size);
        if (!downloadOver && frame) {
            let pkgLen = frame.length + (needHeader ? headerLength : 0);
            if (pos + pkgLen < size) {
                let pkg = new Uint8Array(pkgLen);
                if (needHeader) {
                    let header = Uint8Array.from([0x00, 0x00, 0x00, 0x03, (frame.length >> 24 & 0xFF), (frame.length >> 16 & 0xFF), (frame.length >> 8 & 0xFF), frame.length & 0xFF]);
                    pkg.set(header);
                    pkg.set(frame, headerLength);
                } else {
                    pkg.set(frame);
                }
                
                fileBuf.set(pkg, pos);
                pos += pkg.length;
            } else {
                let data = new Blob([fileBuf.buffer], {type: 'application/octet-stream'});
                let downloadUrl = window.URL.createObjectURL(data);
                let anchor = document.createElement('a');
                anchor.href = downloadUrl;
                anchor.download = 'casVideo.h264';
                document.body.appendChild(anchor);
                anchor.click();
                // 然后移除
                document.body.removeChild(anchor);
                window.URL.revokeObjectURL(data);
                downloadOver = true;
            }
        }
    };
})();
