import Logger from './Logger';
import Util from './Util';

const FULLSCREEN_CHANGE = ['webkitfullscreenchange', 'mozfullscreenchange', 'fullscreenchange', 'msFullscreenChange'];

export default class FullScreen {
    constructor(options) {
        this.options = {...options};
        this.inFullscreenStatus = false;
        this.fullscreenEle = null;
        this.util = new Util();
    }

    init() {
        FULLSCREEN_CHANGE.forEach(evt => {
            this.util.bind(document, evt, this.refreshFullState.bind(this));
        });
    }

    enterFullCreen() {
        Logger.debug('enterFullCreen');
        if (!this.fullscreenEle) {
            return;
        }
        
        const requestMethod = this.fullscreenEle.requestFullScreen // W3C
        || this.fullscreenEle.mozRequestFullScreen // Chrome 等
        || this.fullscreenEle.webkitRequestFullScreen // FireFox
        || this.fullscreenEle.msRequestFullScreen; // IE11
        if (requestMethod) {
            requestMethod.call(this.fullscreenEle);
        }
    }
        
    exitFullscreen() {
        Logger.debug('exitFullscreen');
        if (!this.fullscreenEle) {
            return;
        }

        const exitMethod = document.exitFullscreen // W3C
        || document.mozCancelFullScreen // FireFox
        || document.webkitExitFullscreen // Chrome 等
        || document.msExitFullscreen; // IE11
        if (exitMethod) {
            exitMethod.call(document);
        }
    }

    refreshFullState() {
        if (!this.fullscreenEle) {
            return;
        }

        this.inFullscreenStatus = Boolean(document.fullscreenElement
            || document.msFullscreenElement
            || document.mozFullScreenElement
            || document.webkitFullscreenElement);
        Logger.debug('inFullscreenStatus = ', this.inFullscreenStatus);

        // 按下F11键进入全屏或退出全屏，不会触发resize事件，强制触发，否则触控点错位
        window.dispatchEvent && window.dispatchEvent(new Event('resize')); 
    }

    fullscreenToggle(fullscreenElementId) {
        if (!fullscreenElementId && this.options.autoRotate) {
            Logger.debug('Can not use fullscreenToggle API success, because fullscreenElementId is undefined but auto_rotate is true');
            return;
        }

        Logger.debug('toggle: inFullscreenStatus = ', this.inFullscreenStatus);
        this.fullscreenEle = document.getElementById(fullscreenElementId || this.options.containerId);
        if (this.inFullscreenStatus) {
            this.exitFullscreen();
        } else {
            this.enterFullCreen();
        }
    }

    isFullscreen() {
        return this.inFullscreenStatus;
    }

    destroy() {
        FULLSCREEN_CHANGE.forEach(evt => {
            this.util.unbind(document, evt);
        });
    }
}
