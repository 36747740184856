import PROTOCOL_CONFIG from './config/protocolConfig';
import Util from './Util';

const KETCODE_ENTER_NUMBER_ZONE = 108;
const KETCODE_ENTER_CTRL_ZONE = 13;

export default class KeyboardInput {
    constructor(containerId, msgHandler) {
        this.containerId = containerId;
        this.msgHandler = msgHandler;
        this.util = new Util();
        this.inputContainer = null;
        this.input = null;
        this.inputId = 'keyboardInput' + Date.now();
    }

    init() {
        const videoContainer = document.getElementById(this.containerId);
        if (!videoContainer) {
            return;
        }

        this.inputContainer = document.createElement('div');
        this.input = document.createElement('input');
        this.input.id = this.inputId;
        this.input.type = 'text';
        this.inputContainer.style.cssText = `
            display: none;
            position: absolute;
            top: 0px;
            left: 10%;
            height: 40px;
            width: 80%;
            `;
        this.input.style.cssText = `
            height: 40px;
            width: 100%;
            color: white;
            background-color: rgb(37,43,58);
            opacity: 0.8;
            `;
        this.util.bind(this.input, 'input', this.onInput.bind(this));
        this.util.bind(this.input, 'keyup', this.onKeyup.bind(this));
        this.inputContainer.appendChild(this.input);
        videoContainer.appendChild(this.inputContainer);
    }

    start(inputText) {
        this.input.value = inputText;
        this.inputContainer.style.display = 'block';
        this.input.focus();
    }

    stop() {
        this.input.blur();
        this.inputContainer.style.display = 'none';
    }

    blurInput() {
        this.input.blur();
    }

    onInput() {
        this.handleInputMsg(this.input.value, 'KEYBOARD_INPUT');
    }

    onKeyup(event) {
        if (event.keyCode === KETCODE_ENTER_NUMBER_ZONE || event.keyCode === KETCODE_ENTER_CTRL_ZONE) {
            this.stop();
        }
    }

    handleInputMsg(input, msgType) {
        const PACKAGE_HEADER_LENGTH = 8;
        const INPUT_HEADER_LENGTH = 3;
        const msgData = this.util.encodeUTF8(input);
        const cmdBuf = new Uint8Array(PACKAGE_HEADER_LENGTH + INPUT_HEADER_LENGTH + msgData.length);
        cmdBuf[0] = 90;
        cmdBuf[1] = 90;
        cmdBuf[2] = 180 + PROTOCOL_CONFIG.MSG_TYPE[msgType];
        cmdBuf[3] = PROTOCOL_CONFIG.MSG_TYPE[msgType];
        cmdBuf[4] = (INPUT_HEADER_LENGTH + msgData.length) >> 24;
        cmdBuf[5] = (INPUT_HEADER_LENGTH + msgData.length) >> 16;
        cmdBuf[6] = (INPUT_HEADER_LENGTH + msgData.length) >> 8;
        cmdBuf[7] = (INPUT_HEADER_LENGTH + msgData.length);
        cmdBuf[8] = PROTOCOL_CONFIG.KEYBOARD_INPUT_MSG_TYPE.TEXT_UPDATE;
        cmdBuf[9] = msgData.length >> 8;
        cmdBuf[10] = msgData.length;
        cmdBuf.set(msgData, PACKAGE_HEADER_LENGTH + INPUT_HEADER_LENGTH);
        this.msgHandler(cmdBuf.buffer);
    }

    destroy() {
        this.util.unbind(this.input, 'input');
        this.util.unbind(this.input, 'keyup');
        this.inputContainer.remove();
        this.inputContainer = null;
    }
}
