import H264bsdModule from '@cloud/h264bsd-decoder';

const H264bsdWasm = H264bsdModule.H264bsdWasm;
const H264bsdDecoder = H264bsdModule.H264bsdDecoder;

const moduleInstance = new H264bsdWasm();
moduleInstance.bindEvent('asmReady', () => {
    moduleInstance.decoder = new H264bsdDecoder(moduleInstance);
    moduleInstance.decoder.onPictureReady = function () { // 此处 this 为 decoder，不要使用箭头表达式
        const width = this.outputPictureWidth();
        const height = this.outputPictureHeight();
        const croppingParams = this.croppingParams();
        const output = this.nextOutputPicture();
    
        self.postMessage({
            type: 'pictureReady',
            width: width,
            height: height,
            croppingParams: croppingParams,
            data: output.buffer
        }, [output.buffer]);
    };

    moduleInstance.decoder.onHeadersReady = function () { // 此处 this 为 decoder，不要使用箭头表达式
        const width = this.outputPictureWidth();
        const height = this.outputPictureHeight();
        const croppingParams = this.croppingParams();
    
        self.postMessage({
            type: 'pictureParams',
            width: width,
            height: height,
            croppingParams: croppingParams
        });
    };
});

function decodeLoop() {
    if (!moduleInstance.decoder) {
        return;
    }

    const result = moduleInstance.decoder.decode();

    switch (result) {
    case H264bsdDecoder.ERROR:
        self.postMessage({type: 'decodeError'});
        break;
    case H264bsdDecoder.PARAM_SET_ERROR:
        self.postMessage({type: 'paramSetError'});
        break;
    case H264bsdDecoder.MEMALLOC_ERROR:
        self.postMessage({type: 'memAllocError'});
        break;
    case H264bsdDecoder.NO_INPUT:
        self.postMessage({type: 'noInput'});
        break;
    default:
        decodeLoop();
    }
}

self.addEventListener('message', evt => {
    if (!moduleInstance.decoder) {
        return;
    }

    const message = evt.data;
    switch (message.type) {
    case 'queueInput':
        moduleInstance.decoder.queueInput(message.data);
        decodeLoop();
        break;

    default:
        // do nothing
    }
});

export default function () {}
