import H264bsdModule from '@cloud/h264bsd-decoder';
import work from 'webworkify-webpack';
import Logger from './Logger';

class CanvasPlayer {
    constructor() {
        this.canvas = document.createElement('canvas');
        this.h264bsdCanvas = new H264bsdModule.H264bsdCanvas(this.canvas);

        /* javascript-obfuscator:disable */
        this.videoDecoderWorker = work(require.resolve('./worker/VideoDecoder.js'));
        
        /* javascript-obfuscator:enable */
        this.videoDecoderWorker.addEventListener('message', this.messageHandler.bind(this));
    }

    messageHandler(evt) {
        const message = evt.data;
        if (!Object.prototype.hasOwnProperty.call(message, 'type')) {
            return;
        }

        switch (message.type) {
            // worker 中 onHeadersReady 被调用时触发
            case 'pictureParams': {
                const croppingParams = message.croppingParams;
                if (croppingParams === null) {
                    this.canvas.width = message.width;
                    this.canvas.height = message.height;
                } else {
                    this.canvas.width = croppingParams.width;
                    this.canvas.height = croppingParams.height;
                }

                break;
            }

            // worker 中 onPictureReady 被调用时触发
            case 'pictureReady':
                /*global __IS_DEBUG__*/
                if (__IS_DEBUG__) {
                    let traceId = window.delayAnalysis.shiftTraceId('decode');
                    window.delayAnalysis.record(['decode', 'end', traceId]);
                }

                this.h264bsdCanvas.drawNextOutputPicture(
                    message.width,
                    message.height,
                    message.croppingParams,
                    new Uint8Array(message.data)
                );
                break;

            // 当前队列中所有数据被处理完后触发
            case 'noInput':
                break;

            // decode 方法返回错误码
            case 'decodeError':
            case 'paramSetError':
            case 'memAllocError':
                Logger.debug('Decoder error: ' + message.type);
                break;

            default:
                // do nothing
        }
    }

    decode(data) {
        /*global __IS_DEBUG__*/
        if (__IS_DEBUG__) {
            let traceId = window.delayAnalysis.shiftTraceId('receive') || '';
            window.delayAnalysis.cacheTraceId('decode', traceId);
            window.delayAnalysis.record(['decode', 'start', traceId]);
        }

        this.videoDecoderWorker.postMessage({
            type: 'queueInput',
            data: data.buffer
        }, [data.buffer]);
    }

    destroy() {
        this.videoDecoderWorker.terminate();
    }
}

export default CanvasPlayer;
