const PROTOCOL_CONFIG = {
    MSG_TYPE: {
        INVALID: 0, // 无效
        VERIFY: 1, // 认证
        AUDIO: 2, // 音频数据
        VIDEO: 3, // 视频编码后数据
        LOGIN: 4, // 登录结果
        PAY: 5, // 支付结果
        TOUCH: 6, // 触摸数据
        GAME_CONTROL: 7, // 游戏控制
        HEART_BEAT: 8, // 心跳数据
        ORIENTATION: 9, // 旋转
        ORIENTATION_TimeStamp: 10, // 旋转与出帧时间
        KEYBOARD_INPUT: 14,
        CUSTOM_DATA: 18
    },
    CMD_TYPE: {
        HEART_BEAT: '0',
        START_APP: '1',
        STOP_APP: '2',
        RECONNECT: '3',
        PAUSE: '4',
        RESUME: '5',
        SET_RESOLUTION: '6',
        SET_MEDIA_CONFIG: '8',
        ENABLE_REMOTE_IME: '9'
    },
    CMD_RESULT: {
        FAIL: '0',
        SUC: '1'
    },
    CMD_RESP_TYPE: {
        CONNECT_FAILED: 0x03,
        VERIFY_FAILED: 0x06,
        START_FAILED: 0x09,
        PAUSE_TIMEOUT: 0x10,
        PLAY_TIMEOUT: 0x0E,
        TOUCH_TIMEOUT: 0x0F,
        RESOLUTION_SUCCESS: 0x19,
        MEDIA_CONFIG_SUCCESS: 0x21,
        START_GAME_SUCCESS: 0x08,
        RECONNECT_FAILED: 0x0D,
        INVALID_OPERATION: 0xFF
    },
    ACTIONS_TYPE: {
        DOWN: 0,
        UP: 1,
        MOVE: 2,
        POINTER_DOWN: 5,
        POINTER_UP: 6
    },
    HEADER_LENGTH: 8,
    ORIENTATION: {
        0: 'PORTRAIT',
        1: 'LANDSCAPE' // 从竖屏逆时针旋转90度
    },
    RESOLUTIONS: {
        DISPLAY_480P: {
            width: 480,
            height: 848
        },
        DISPLAY_540P: {
            width: 544,
            height: 960
        },
        DISPLAY_720P: {
            width: 720,
            height: 1280
        },
        DISPLAY_1080P: {
            width: 1080,
            height: 1920
        }
    },
    DEFAULT_RESOLUTION: {
        width: 720,
        height: 1280
    },
    KEYBOARD_INPUT_MSG_TYPE: {
        INPUT_EVENT: 0x00,
        HIDE_KEYBOARD_EVENT: 0x01,
        TEXT_UPDATE: 0x02
    },
    KEYBOARD_INPUT_HEADER_LENGTH: 3,
    REMOTE_IME: {
        CLOUD_PHONE_KEYBOARD: '0',
        CLIENT_KEYBOARD: '1'
    },
    PHONE_CONNECTION_STATE: {
        1: {
            state: 1,
            message: 'The Phone has been connected by other user.'
        },
        2: {
            state: 2,
            message: 'Connection number exceeds the limit.'
        },
        3: {
            state: 3,
            message: 'Connection to phone failed.'
        },
        4: {
            state: 4,
            message: 'No operations for a long time.'
        },
        5: {
            state: 5,
            message: 'Please log in to the cloud phone again.'
        }
    }
};

export default PROTOCOL_CONFIG;
