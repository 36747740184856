import OpusDecoder from '@cloud/opus-decoder';

const decoder = new OpusDecoder(48000, 2);

self.addEventListener('message', evt => {
    const message = evt.data;
    switch (message.type) {
    case 'decode': {
        let pcmData = decoder.decodeFloat(message.data);
        self.postMessage({
            type: 'decodeResult',
            data: pcmData.buffer
        }, [pcmData.buffer]);

        break;
    }

    default:
        // do nothing
    }
});

export default function () {}
